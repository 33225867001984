import Splide from '@splidejs/splide';
import InfoBadge from './info-badge';
const sliderContainerSelector = '[data-products-slider]';
const sliderContentSelector = '[data-product-slider]';
const sliderNavSelector = '[data-products-slider-nav]';
const sliderNavTarget = 'data-products-slider-tag';
const activeClass = 'is-active';

class productsSlider {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.blogNav = this.root.querySelectorAll(sliderNavSelector);
        this.blogNavInit = this.root.querySelector(sliderNavSelector);
        this.sliderContent = this.root.querySelector(sliderContentSelector);
        this.tag = false;

        if (this.blogNavInit) {
            this.tag = this.blogNavInit.getAttribute(sliderNavTarget);
        }

        return true;
    }

    init() {
        this.initSlider(this.sliderContent);
        this.initFilters();
    }

    initBadges() {
        this.badgeEl = this.root.querySelectorAll('[data-info-badge]');
        if (!this.badgeEl) return;

        this.badgeEl.forEach(item => new InfoBadge(item));
    }

    initFilters() {
        if (!this.blogNav) return;

        this.blogNav.forEach(item => {
            item.addEventListener('click', event => {
                this.blogNav.forEach(innerItem => {
                    innerItem.classList.remove(activeClass);
                });
                const { currentTarget } = event;
                this.tag = currentTarget.getAttribute(sliderNavTarget);
                currentTarget.classList.add(activeClass);
                this.getPosts();
            });
        });
    }

    initSlider(component) {
        let sliderSplide = new Splide(component, {
            perPage: 4,
            gap: 16,
            focus: 0,
            rewind: false,
            pagination: false,
            arrows: false,
            drag: true,
            breakpoints: {
                1200: {
                    perPage: 2.5,
                },
                900: {
                    perPage: 2.2,
                },
                767: {
                    perPage: 1.5,
                },
            },
        });
        sliderSplide.mount();
    }

    async getPosts() {
        this.root.classList.add('is-loading');

        const formData = new FormData();
        formData.append('tag', this.tag);

        const request = await fetch(
            `${home_url}/wp-json/mda-rest-api-frontend/products-slider/get`,
            {
                method: 'POST',
                body: formData,
            }
        );
        const res = await request.json();

        if (res) {
            this.root.classList.remove('is-loading');
            this.sliderContent.innerHTML = res.view;
            this.initSlider(this.sliderContent);
            this.initBadges();
        }
    }
}

function initProductsSlider() {
    const sections = document.querySelectorAll(sliderContainerSelector);
    if (!sections) return;

    sections.forEach(item => new productsSlider(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initProductsSlider();
} else {
    document.addEventListener('DOMContentLoaded', initProductsSlider);
}
