export default class Popup {
    constructor(el, removeOnClose = true) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el, removeOnClose) {
        if (!el) return false;

        this.root = el;
        this.closeEl = this.root.querySelectorAll('[data-popup-close]');
        this.removeOnClose = removeOnClose;

        return true;
    }

    init() {
        this.setClose();
    }

    setClose() {
        if (!this.closeEl) return;

        this.onClose = this.onCloseClick.bind(this);
        this.closeEl.forEach(item => {
            item.addEventListener('click', this.onClose);
        });
    }

    onCloseClick() {
        this.close();
    }

    open() {
        this.root.classList.add('-active');
    }

    close() {
        this.root.classList.remove('-active');
        document.body.style.overflowY = 'unset';

        if (this.removeOnClose) {
            setTimeout(() => {
                this.root.remove();
            }, 800);
        }
    }
}
