import Popup from '../modules/_popup';

export const loadPopup = async (popupEl, popup, formData) => {
    const request = await fetch(`${home_url}/wp-json/popup/v1/get`, {
        method: 'POST',
        body: formData,
    });

    const res = await request.json();
    const { html, id } = res;

    document.body.insertAdjacentHTML('beforeend', html);
    popupEl = document.getElementById(id);
    popup = new Popup(popupEl);
    popup.open();
};
