import { loadPopup } from '../helper/_popup';

export default class InfoBadge {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.id = this.root.getAttribute('data-target');

        this.popupEl = null;
        this.popup = null;

        this.popupData = JSON.parse(this.root.getAttribute('data-popup-data'));
        this.loadPopup = loadPopup;

        return true;
    }

    init() {
        this.setCard();
    }

    setCard() {
        this.onCard = this.onCardClick.bind(this);
        this.root.addEventListener('click', this.onCard);
    }

    async onCardClick(event) {
        event.preventDefault();

        const formData = new FormData();
        if (this.popupData)
            formData.append('popup-data', JSON.stringify(this.popupData));

        await this.loadPopup(this.popupEl, this.popup, formData);
    }

    async loadPopup() {
        const request = await fetch(`${home_url}/wp-json/popup/v1/get`, {
            method: 'POST',
            body: formData,
        });

        const res = await request.json();
        const { html, id } = res;

        document.body.insertAdjacentHTML('beforeend', html);
        this.popupEl = document.getElementById(id);
        this.popup = new Popup(this.popupEl);
        this.popup.open();
    }
}

function initInfoBadge() {
    const sections = document.querySelectorAll('[data-info-badge]');
    if (!sections) return;

    sections.forEach(item => new InfoBadge(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initInfoBadge();
} else {
    document.addEventListener('DOMContentLoaded', initInfoBadge);
}
